.GREEN{
    background-color: #00c851D0 !important;
    height: 70px;
    border-radius: 5px;
    font-size: large;
    font-weight: 600 ;
    color:#FFFFFF;
    display: flex;
    width: 100%;
    margin: auto;
    align-items:center;
    justify-content:center;
}
.RED{
    background: #ff3547D0;
    height: 70px;
    border-radius: 5px;
    font-size: large;
    font-weight: 600 ;
    color:#FFFFFF;
    display: flex;
    width: 100%;
    margin: auto;
    align-items:center;
    justify-content:center;

}
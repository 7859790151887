
.TableSelectionPositioning {
    position: absolute;
    top: 2.5rem;
    right: 4.5rem;
   
 
 }
 #leftpart{
     border-style: solid ;
     border-width: 3px;
     border-color:#212121;
     border-top-left-radius: 20px;
     border-bottom-left-radius: 20px;
     border-top-right-radius: 0px !important;
     border-bottom-right-radius: 0px !important;
     padding: 10px;
     font-size: 1rem;
     text-align: center;
     color:#3b3b3b !important;
     font-weight: 600;
     text-transform:lowercase !important;

 }
 #rightpart{
    border-style: solid ;
    border-left-width: 0px !important;
    border-width: 3px;
    border-color:#212121;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    font-size: 1rem;
    padding: 10px;
    text-align: center;
    color:#3b3b3b !important;
    font-weight: 600;
    text-transform:lowercase !important;
   

 }
 #rightpart:hover{
     
    background-color:#00000010;
   

 }
 #leftpart:hover{
    background-color:#00000010;

 }
 .clickedBlackOpacity{

    background-color:#00000010 !important;
 }
/* 
.imageLogo{
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    justify-content: center;
}

.sidebar {
  height: 100%;
  width: 220px;
  position: fixed;
  overflow-y: hidden;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #5C4EA0;
  overflow-x: hidden;
  padding-top: 16px;
  transition: ease all .5s;

}
.sidebar a {
  padding: 14px 8px 12px 16px;
  text-decoration: none;
  font-size: 0.8rem;
  color: #FFFFFF;
  display: block;
  position: relative;
  border-radius: 100px 0 0 100px;
  transition: color 0.5s;
}
.sidebar a:hover {
  background: #fff;
  color: #5C4EA0 !important;
}

.sidebar a::after,
.sidebar a::before {
  content: "";
  position: absolute;
  top: -50px;
  right: 0;
  width: 20px;
  height: 50px;
  border-radius: 0 0 25px 0;
  z-index: -1;
}
.sidebar a::before {
  top: auto;
  bottom: -50px;
  border-radius: 0 25px 0 0;
}
.sidebar a:hover::after {
  box-shadow: 0 25px 0 0 rgb(255, 255, 255);
}
.sidebar a:hover::before {
  box-shadow: 0 -25px 0 0 #fff;
}

.imageSmall{
  display: none;
}

@media screen and (max-width: 850px) {
  .sidebar {
    width: 60px;
    transition: ease all .5s;

  }
  .menu-icon {
    margin-left: 0px;
    margin-right: 10px;
  }
  .menu-text {
    display: none;
  }
  .imageGrand{
    display: none;
  }  
  .imageSmall{
    display: block;
  }
}
*/

.menu-text {
  margin: 0;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #fff;
  font-size: 1.5rem;
}
.menu-icon {
  margin-left: 14px;
  margin-right: 10px;
  height: 2rem !important;
  width: 2rem !important;
}
.menu-icon-responsive {
  position: absolute;
  color: #e64a19;
  /* color : #f4511e; */
  font-size: 2.5rem !important;
  margin-top: 15px;
  margin-left: 20px;
  display: none !important;
}
.menu-text-responsive {
  margin-left: 20px;
  font-size: 1.3rem;
}
.menu-all-icons-responsive {
  margin-top: 17px;
  font-size: 2.5rem !important;
}
.imageLogo {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
}
.imageSmall {
  display: none;
}
.sidebar {
  height: 100%;
  /* width: 250px; */
  width: 350px;
  position: fixed;
  overflow-y: hidden;
  z-index: 1;
  top: 0;
  left: 0;
  /* background-color: #5c4ea0; */
 

  background-color: #212121;
  overflow-x: hidden;
  padding-top: 16px;
  transition: ease all 0.2s;
}
.sidebar-responsive {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: hidden;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #212121;
  overflow-x: hidden;
  padding-top: 16px;
  display: none;
}
.show {
  right: 0;
  transition: 1s;
}
.hide {
  right: 2000px;
  transition: 1s;
}
.sidebar a {
  padding: 12px 8px 12px 16px ;
  text-decoration: none;
  font-size: 1.5rem;
  color: #ffffff;
  display: block;
  position: relative;
  border-radius: 100px 0 0 100px;
  transition: color 0.5s;
}
.sidebar-responsive a {
  padding: 4px 8px 5px 16px;
  text-decoration: none;
  font-size: 0.8rem;
  color: #ffffff;
  height: 50px;
  margin-left: 40px;
  display: block;
  transition: color 0.5s;
}
.sidebar a:hover {
  background: #fff;
  color: #212121 !important;
}
.sidebar a::after,
.sidebar a::before {
  content: "";
  position: absolute;
  top: -50px;
  right: 0;
  width: 20px;
  height: 50px;
  border-radius: 0 0 25px 0;
  z-index: -1;
}
.sidebar a::before {
  top: auto;
  bottom: -50px;
  border-radius: 0 25px 0 0;
}
.sidebar a:hover::after {
  box-shadow: 0 25px 0 0 rgb(255, 255, 255);
}
.sidebar a:hover::before {
  box-shadow: 0 -25px 0 0 #fff;
}
@media screen and (max-width: 1100px) {
  /* 1000px */
  .sidebar {
    width: 60px;
    transition: ease all 0.5s;
  }
  .menu-icon {
    margin-left: 0px;
    margin-right: 10px;
  
    /* margin-top: 10px; */
  }
  .menu-text {
    display: none;
  }
  .imageGrand {
    display: none;
  }
  .imageSmall {
    display: block;
  }
}
.menu-icon-responsive {
  display: none;
}
@media screen and (max-width: 500px) {
  .sidebar {
    display: none;
    width: 60px;
    transition: ease all 0.5s;
  }
  .menu-icon-responsive {
    display: block !important;
  }
  .sidebar-responsive {
    display: block;
  }
}

body {
    margin: 0px;
    padding: 0px;
  }
  .DashboardName {
    margin-left: 10px;
    margin-bottom: 15px;
    text-align: left;
    font-size: 17px;
    color: #000000;
  }
  .Dashboard {
    display: flex;
    margin-left: 350px;
    /* padding: 0px; */
  }
  .main {
    flex: 2;
    margin: 25px 60px;
  }
  @media screen and (max-width: 1100px) {
    .Dashboard {
      margin-left: 60px;
      transition: ease all 0.5s;
    }
  }
  @media screen and (max-width: 500px) {
    .Dashboard {
      margin-left: 0px;
      transition: ease all 0.5s;
    }
    .main {
      margin: 10px 0;
    }
    .DashboardName {
      font-size: 14px;
      margin-top: 4px;
      margin-left: 80px;
    }
  }

.exploreButton{
    padding: 15px;

    color:#FFFFFF;
    font-size: medium;
    font-weight: 900;
    border-color: #FFFFFF;
    border-radius: 5%;
    }
.exploreButton:hover{
        opacity: 0.9 !important;
        }
.deliveringtableContainerStyle {
            box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 20px !important;
            border: 0px !important;
            border-radius : 2px 2px 10px 10px !important;
            background-color: #e5e5e5 !important;
        }
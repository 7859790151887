.creationCard {
    position: relative !important;
    box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 20px !important;
        border: 0px !important;
    width: 100%;
    height: 100%;


    border-radius:10px !important;
    
}
.editIcon2{
padding: 15px;
border-radius: 50%;
box-shadow:rgba(0, 0, 0, 0.3) 1px 5px 20px !important;
background-color: #212121;
color:#FFFFFF;
}
.hidden{
    width: 16.1rem;
}
.editIcon2:hover{
opacity: 0.9 !important;
}
.cornerText{
font-size: large;
font-weight: 600 ;
color:#3b3b3b;


}
.littleTitle{
color:#3b3b3b;
font-size: medium !important;
font-weight: 600 !important;
}
.errorlittleTitle{
color:#F44336;
font-size: medium !important;
font-weight: 600 !important;
}
.padding{
    padding: 0.3rem 2rem;
}
.bigTitle{
    color:#3b3b3b;
    font-size: large !important;
    font-weight: 600 !important;
 

}
.orderInfoContainer{


    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 80% !important;
    padding: 15px;
    border-radius: 30px;
    background-color: #212121;
}
.normalButton2{
    width: 100px !important;    
    position: absolute !important;
    bottom: 7px !important;
    left: 7px !important;
    color:#3b3b3b;
    font-size: large !important;
    font-weight: 600 !important;
    color : "#FFFFFF";
    text-transform: capitalize !important;
    background-color: #212121 !important;
    }
.tableContainerStyle {
    box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 20px !important;
        border: 0px !important;
border-radius : 2px 2px 10px 10px !important;
}
.filtertitle  {
text-align: left;
font: "normal normal medium 20px/25px !important";
letter-spacing: "0px";
color: "#9A9A9A";
opacity: 1;
}
.selectStyle {
width: 17rem !important; 

color:  #3b3b3b;
background:  #F3F6F9;
border: #e5e8e9 !important;    
border-radius: 5px !important;



}

.smallSelectStyle{
width: 8rem !important;
height: 2.39rem; 
position: relative;

color:  #3b3b3b;
background:  #FFFFFF;
border: #e5e8e9 !important;    
border-radius: 5px !important;
}
.justifycenter{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
}
.searchCard{
position: relative ;
box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 20px !important;
border: 0px !important;
width: 100%;
height: 100%;

border-radius:10px 10px 0px 0px !important;

/* border-bottom-left-radius:0px ;
border-bottom-right-radius:0px ; */
}


.OrderTotalHidden{
    display: none;
}
.OrderDisplayed{
    display: block;
    transition: 0.2s ease-in-out !important;

}
.blueText{
    color: #212121;
}
.searchProductIconContainer{

    box-shadow:rgba(0, 0, 0, 0.2) 1px 5px 20px !important;
    background-color: #212121;
    color:#FFFFFF;
    border-radius: 5px;
    height:32px !important;
    padding: 13px 13px;
    width:32px !important;

}
.searchProductIconContainer:hover{
    opacity: 0.9 !important;
}
.addProductIconContainer{
    padding: 3px;
    border-radius: 50%;
    box-shadow:rgba(0, 0, 0, 0.3) 1px 5px 20px !important;
    background-color: #4CAF50;
    color:#FFFFFF;
 

}
.addProductIconContainer:hover{
    opacity: 0.9 !important;
    }
.addPaymentIconContainer{
    padding: 1px;
    border-radius: 50%;
    box-shadow:rgba(0, 0, 0, 0.3) 1px 5px 20px !important;
    background-color: #4CAF50;
    color:#FFFFFF;

}
.addPaymentIconContainer:hover{
    opacity: 0.9 !important;
    }
.removeProductIconContainer{
    font-size: 10px;
    padding: 3px;
    border-radius: 50%;
    box-shadow:rgba(0, 0, 0, 0.3) 1px 5px 20px !important;
    background-color: #F44336;
    color:#FFFFFF;


}
.insertPhotoButton{
    border:3px solid #4CAF50 !important; 

}
.removeProductIconContainer:hover{
    opacity: 0.8 !important;
    }
.removePaymentIconContainer{
    padding: 1px;
    border-radius: 50%;
    box-shadow:rgba(0, 0, 0, 0.3) 1px 5px 20px !important;
    background-color: #F44336;
    color:#FFFFFF;

}
.removePaymentIconContainer:hover{
    opacity: 0.8 !important;
    }


.notFoundProductInputBorder{
    border: 10px #F44336 !important;
}


/* 

Online Offline animation !

*/

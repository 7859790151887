div.online-indicator {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    
    background-color: #0fcc45;
    border-radius: 50%;
    
    position: relative;
  }
span.greenblink {
    display: block;
    width: 15px;
    height: 15px;
    
    background-color: #0fcc45;
    opacity: 0.7;
    border-radius: 50%;
    
    animation: blink 1s linear infinite;
  }
@keyframes blink {
    100% { transform: scale(2, 2); 
            opacity: 0;
          }
  }
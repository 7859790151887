.creationCard {
        box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 20px !important;
        border: 0px !important;
        width: 100%;
        height: 100%;
      
        background-color: #FFFFFF !important;
        border-radius:10px !important;
        
}
.tableContainerStyle {
    box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 20px !important;
    border: 0px !important;
    border-radius : 2px 2px 10px 10px !important;
}
.searchCard{
    box-shadow: rgba(0, 0, 0, 0.11) 0px 5px 20px !important;
    border: 0px !important;
     
    width: 100%;
    height: 100%;

  
    border-radius:10px 10px 0px 0px !important;

    /* border-bottom-left-radius:0px ;
    border-bottom-right-radius:0px ; */
}
.editIcon{
    padding: 15px;
    border-radius: 50%;
    box-shadow:rgba(0, 0, 0, 0.3) 1px 5px 20px !important;
    background-color: #2ec4b6;
    color:#FFFFFF;
}
.editIcon:hover{
    opacity: 0.9 !important;
}
.cornerText{
    font-size: large;
    font-weight: 600 ;
    color:#3b3b3b;


}
.littleTitle{
    color:#3b3b3b;
    font-size: medium !important;
    font-weight: 600 !important;
}
.normalButton{
    width: 100px !important;    
    position: absolute !important;
    bottom: 7px !important;
    left: 7px !important;
    color:#3b3b3b;
    font-size: large !important;
    font-weight: 600 !important;
    color : "#FFFFFF";
    text-transform: capitalize !important;
    background-color: #2ec4b6 !important;
    }

.filtertitle  {
    text-align: left;
    font: "normal normal medium 20px/25px !important";
    letter-spacing: "0px";
    color: "#9A9A9A";
    opacity: 1;
}
.MySelectStyle {
    width: 16.1rem !important; 
    
    color:  #3b3b3b;
    background:  #F3F6F9;
    border: #e5e8e9 !important;    
    border-radius: 5px !important;
    
    
    
    }
.birthdayselectStyle{
    color:  #3b3b3b;
    background:  #F3F6F9;
    border: #CDD2D7;    
    border-radius: 5px !important;

}



 /* 
 
Online Offline animation !
    
    */


.myTextAreaStyle{
  width: 15rem;
  height:7rem;
  font-size: 1rem;
  
  font-weight:  500;
  line-height: 1.5;
  color: #3b3b3b;
  background:#F3F6F9;
  /* border: 0px; */
   border: 1px solid #e5e8e9;  
   /* CDD2D7 */
 

  /* border: 1px solid #F44336 ; */
  border-radius: 5px;
  padding: 8px 8px;

}
textarea {
  resize: none;
}
.myTextAreaStyle:hover {
    background: #E7EBF0;
    border-color: #B2BAC2;
  }
.myTextAreaStyle:focus {
    outline: 3px solid #DAECFF;
  }
.notFoundProductInputBorder{
  border:4px solid #F44336 !important;
}
.FoundProductInputBorder{
  border:4px solid #4CAF50 !important;
}
@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
.Global_container  {
    min-height: 100vh;
    background-color: #EFECEC ;

  }
.globalLogo{
  font-size: 64px;
  font-family: 'Bangers', cursive;

}
body{
  min-height: 100vh;
  /* overflow: hidden; */
}

  .bigMsg {
    text-align: center;
    font-size: 2.9em !important;
    margin-bottom: 10% !important;
  }
  .smallMSg {
    text-align: center;
    font-weight: 100 !important;
    font-size: 1.2em !important;
  }
  .left_card {
    height: 70%;
    color: white;
    background-color: #212121;
    border-radius: 3% 0 0 3%;
    
  }
  .right_card {
    height: 70%;
    color: #212121;
    background-color: white;
    border-radius: 0 3% 3% 0;
  }
  .LogoSvg {
    width: 50%;
    margin-left: 4%;
    

  }
  .left_card_content {
    padding: 1.5rem;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .right_card_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .right_bigMsg {
    padding-bottom: 1rem !important;
  }
  .form_input_field {
    margin-bottom: 7% !important;
  }
  .login_btn_valid {
    background-color: #212121 !important;
    color: white !important;
    width: 100%;
    padding: 0.7vw !important;
    margin-bottom: 7% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 0.5rem !important;
    transition: 0.2s ease-in-out !important;
  }
  .login_btn_disabled {
    color: white !important;
    border-radius: 3rem !important;
    width: 50%;
    margin-bottom: 7% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    background: #808080 0% 0% no-repeat padding-box !important;
    pointer-events: none;
  }
  .form_login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobile_upper_content {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: space-evenly;
  }
  .circle_left_bottom {
    height: 34vh;
    width: 34vh;
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 100%;
    position: absolute;
    left: -100px;
    bottom: -100px;
    z-index: 1;
  }
  .circle_right_top {
    height: 35vh;
    width: 35vh;
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 100%;
    position: absolute;
    right: -100px;
    top: -100px;
    z-index: 1;
  }
  .imageLogo2 {
    display: flex;
    margin-top: 30px;
    margin-bottom: 10px;
    justify-content: center;
  }
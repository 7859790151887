
#updateBox {
    padding: 5% !important;
    background-color: aliceblue;
    border-style: none !important;
    border-radius: 20px;
    width: 400;
    height: 800;

}
.cornerText{

    font-size: large !important;
    font-weight: 600 !important;
    color:#3b3b3b !important;


}




#completedButton  {
    background: #4DCB5E  !important;
     border-radius: 20px !important;
     width:120px;
     height: 50px;
      opacity: 1 !important;
      color : white !important;
      text-transform: capitalize !important;
      font-size: large !important;
      padding: 5px 12px !important;

  }
  .McompletedButton  {
    background: #FFCA28  !important;
     border-radius: 20px !important;
     width:130px;
     height: 32px;
      opacity: 1 !important;
      color : white !important;
      text-transform: capitalize !important;
      font-size: medium !important;
      padding: 5px 12px !important;

  }
#completedButtonDisabled  {
    background: #4DCB5E80  !important;
     border-radius: 20px !important;
      opacity: 1 !important;
      width:120px;
      height: 50px;
      color : white !important;
      text-transform: capitalize !important;
      font-size: large !important;
      padding: 5px 12px !important;

  }
  .McompletedButtonDisabled  {
    background: #FFCA2880  !important;
     border-radius: 20px !important;
      opacity: 1 !important;
      width:130px;
      height: 32px;
      color : white !important;
      text-transform: capitalize !important;
      font-size: medium !important;
      padding: 5px 12px !important;

  }
#failedButton  {
    background: #EF5B5B 0% 0% no-repeat padding-box !important;
    border-radius: 20px !important;
    width:120px;
    height: 50px;
    opacity: 1 !important;
    color : white !important;
    text-transform: capitalize !important;
    padding: 5px 12px !important;
    font-size: large !important;
 

}
.MfailedButton  {
    background: #BDBDBD 0% 0% no-repeat padding-box !important;
    border-radius: 20px !important;
    width:130px;
    height: 32px;
    opacity: 1 !important;
    color : white !important;
    text-transform: capitalize !important;
    padding: 5px 12px !important;
    font-size: medium !important;
 

}

#failedButtonDisabled  {
    background: #EF5B5B80 0% 0% no-repeat padding-box !important;
    border-radius: 20px !important;
    width:120px;
    height: 50px;
    text-transform: capitalize !important;
    opacity: 1 !important;
    padding: 5px 12px !important;
    color : white !important;
    font-size: large !important;
 

  }
  .MfailedButtonDisabled  {
    background: #E0E0E0 0% 0% no-repeat padding-box !important;
    border-radius: 20px !important;
    width:130px;
    height: 32px;
    text-transform: capitalize !important;
    opacity: 1 !important;
    padding: 5px 12px !important;
    color : white !important;
    font-size: medium !important;
 

  }
#isActiveContainer{
    padding-left:15%;
    padding-right: 15%;
}
#fermerButton{
    font-size: large !important;
}
#modifierButton{
    font-size: large !important;

}